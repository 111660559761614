@font-face {
    font-family: 'Futura';
    font-style: normal;
    src: local('Futura'), url("./fonts/Futura Bk Book.ttf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: 'Futura Light';
    font-style: normal;
    src: local('Futura Light'), url("./fonts/Futura Lt Light.ttf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: 'Futura-Medium';
    src: local('Futura-Medium'), url("./fonts/Futura-Medium.ttf") format("opentype");
    font-style: normal;
    font-weight: 400;
}

body {
    margin: 0;
    font-family: 'Futura', Futura-Medium, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
    height: 100%;
    background-color: rgb(250, 250, 250);
    font-size: 0.9rem;
}

* {
    font-family: 'Futura', sans-serif !important;
}

h1, h2, h3, h4, h5, h6, strong {
    font-family: 'Futura-Medium', sans-serif !important;
}

.MuiTableCell-root.MuiTableCell-head,
.MuiTableCell-root.MuiTableCell-body {
    padding: 4px 4px;
}

.MuiCardContent-root:last-child {
    padding: 0 !important;
}

@media (-webkit-min-device-pixel-ratio: 1.01) and (-webkit-max-device-pixel-ratio: 1.5) {
    html {
        font-size: 0.7rem;
    }

    .planner-data-holder {
        height: 77vh;
    }
}

.nav-icon-svg > svg {
    height: 22px;
    width: 22px;
}

.megye {
    fill: none;
    stroke: #000000;
    stroke-width: 1px;
}

.megye:hover {
    fill: darkseagreen;
}

#mo {
    fill: none;
    stroke: #d40000;
    stroke-width: 1.5;
}

.nev {
    font-size: 12px;
    text-anchor: middle;
}

#hungary-map-svg {
    cursor: pointer;
}

